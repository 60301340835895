


*,*::after,*::before{
  margin:0;
  padding:0;
  box-sizing:border-box;
  outline: none;
  font-family:'Poppins', sans-serif;
}

.bg-1{
  background-image: url('./assets//images/lorawireless.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-2{
  background-image: url('./assets//images/bg-blue.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-3{
  background-image: url('./assets//images/bg-white.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}







































@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap');


.poppins{
  font-family: 'Poppins', sans-serif;
}
.mont{
  font-family: 'Montserrat', sans-serif;
}
.inter{
  font-family: 'Inter', sans-serif;
}

.bg-home{
  background-image: url('./assets/gradbg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}






@keyframes myAnim {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes myAnim1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0.5;
  }
  80% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
@keyframes myAnim2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.first {
  background-color: rgba(255, 255, 255, 0.779);
  
  width: 650px;
  padding-bottom: 650px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: myAnim2 4s ease-in-out infinite;
  z-index: 50;
  margin-left: -10px;
  margin-top: 40px;
}
.sec {
  background-color: rgba(255, 255, 255, 0.647);
  width: 550px;
  padding-bottom: 550px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: myAnim1 4s ease-in-out infinite;
  z-index: 50;
  margin-left: -10px;
  margin-top: 40px;
}
.third {
  background-color: rgba(255, 255, 255, 0.76);
  position: absolute;
  width: 450px;
  padding-bottom: 450px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: myAnim 4s ease-in-out infinite;
  z-index: 50;
  margin-left: -10px;
  margin-top: 40px;
}
@media screen and (max-width:1324px) {
  .third{
    width: 300px;
    padding-bottom: 300px;
  }
  .sec{
    width: 350px;
    padding-bottom: 350px;
  }
  .first{
    width: 400px;
    padding-bottom: 400px;
  }
  
}


.shadow-1{
  box-shadow: 1px -1px 1px #787878;
}

.direct-con{
  &::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
  }
}

.grad-1{
  background: radial-gradient(144.67% 83.95% at 21.22% 49.95%, #000 0%, #4B4B4B 100%);
}
.grad2{
  background: var(--RAD4, radial-gradient(167.52% 104.41% at 14.34% 65.37%, #190C20 0%, #9A51D7 100%));
}

.heading-lora{
  text-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
  background: linear-gradient(90deg, #0BFFC2 0%, #03A0FF 82.48%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

}

.transi{
  transition: visibility 0s, opacity 1s ease-in-out;

}

.active{
  background-color: white;
  border-radius: 1000px; 
}
.activedark{
  background-color: black;
  border-radius: 1000px; 

}
.active li{
  background: linear-gradient(180deg, #0BFFC2 0%, #03A0FF 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}
.activedark li{
  background: linear-gradient(180deg, #0BFFC2 0%, #03A0FF 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.nav li:hover{
  background: linear-gradient(180deg, #0BFFC2 0%, #03A0FF 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-decoration: underline;

}
.blackdiv{
  width: 79%;
  height: 250px;
  margin-left: 21%;
}
@media screen and (min-width:400px) {
  .blackdiv{
    width: 82%;
    height: 250px;
    margin-left: 18%;
  }
}
@media screen and (min-width:460px) {
  .blackdiv{
    width: 80%;
    height: 250px;
    margin-left: 20%;
  }
}
@media screen and (min-width:590px) {
  .blackdiv{
    width: 78%;
    height: 250px;
    margin-left: 22%;
  }
}
@media screen and (min-width:640px) {
  .blackdiv{
    width: 76%;
    height: 290px;
    margin-left: 24%;
  }
}
@media screen and (min-width:680px) {
  .blackdiv{
    width: 79%;
    height: 290px;
    margin-left: 21%;
  }
}
@media screen and (min-width:768px) {
  .blackdiv{
    width: 79%;
    height: 380px;
    margin-left: 21%;
  }
}
@media screen and (min-width:1024px) {
  .blackdiv{
    width: 79%;
    height: 600px;
    margin-left: 21%;
  }
}
@media screen and (min-width:1202px) {
  .blackdiv{
    width: 82%;
    height: 600px;
    margin-left: 18%;
  }
}
@media screen and (min-width:1280px) {
  .blackdiv{
    width: 76%;
    height: 600px;
    margin-left: 24%;
  }
}
@media screen and (min-width:1410px) {
  .blackdiv{
    width: 78%;
    height: 600px;
    margin-left: 22%;
  }
}
@media screen and (min-width:1688px) {
  .blackdiv{
    width: 77%;
    height: 800px;
    margin-left: 23%;
  }
}

.width-max{
  width: max-content;
}

.prdt-button:hover{
  transition: all ease-in-out 3s;

  background: linear-gradient(90deg, #0BFFC2 0%, #03A0FF 100%) !important;


}


.homeSliderFadeOut{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 1s ease-in-out;
}
.homeSliderFadeIn{
  visibility: visible;
  opacity: 1;

}