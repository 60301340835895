@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');@font-face {
  font-family: "fasttrack";
  src: url("./assets/fonts/FAST-TRACK.ttf");
}
.text-bg-clip {
  background: linear-gradient(to right, #01ffff, #00a3fc);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.font-inter{
  font-family: 'Inter', sans-serif;
}
.bg-about {
  background-image: url("./assets/images/abtbg.png");
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .bg-twice {
  background: linear-gradient(
      30deg,
      rgba(204, 206, 208, 0.5) 36%,
      transparent 28%
    ),
    linear-gradient(110deg, rgba(212, 213, 215, 0.5) 32%, transparent 28%);
} */

.bg-add {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.bg-add::after {
  content: "";
  width: 100%;
  height: 50%;
  background-color: #dddddd;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
}

@media screen and (max-width: 1024px) {
  .bg-about::after {
    background: linear-gradient(
      -122deg,
      rgba(0, 0, 0, 0.3) 43%,
      transparent 28%
    );
  }

  .bg-twice {
    background: linear-gradient(
        60deg,
        rgba(204, 206, 208, 0.5) 36%,
        transparent 28%
      ),
      linear-gradient(110deg, rgba(212, 213, 215, 0.5) 32%, transparent 28%);
  }

  /* .bg-add::after {
    content: "";
    width: 100%;
    height: 50%;
    background-color: #dddddd;
    position: absolute;
    bottom: 0;
    z-index: 0;
  } */
}

/* @media screen and (max-width: 576px) {
  .bg-add::after {
    content: "";
    width: 100%;
    height: 40%;
    background-color: #dddddd;
    position: absolute;
    bottom: 0;
    z-index: 0;
  }
} */

@media screen and (max-width: 1550px) {
  .screenxl {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}
@media screen and (max-width: 764px) {
  .screenxl {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media screen and (min-width: 1550px) {
  .screenxl {
    padding-left: 13rem !important;
    padding-right: 13rem !important;
  }
}

.grad {
  background: var(--l2, linear-gradient(180deg, #FFF 0%, #E6E6E9 72.69%, #E8E8EB 99.8%, #E6E6E9 100%));
}
.shad {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
}

.bg-all {
  background-image: url("./assets//images/bgall.png");
  background-position-x: left;
  background-position-y: bottom;

  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 764px) {
  .bg-all {
    background-image: none;
  }
}

@keyframes myAnim {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes myAnim1 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0.5;
  }
  80% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
@keyframes myAnim2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}



.arrow span {
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 5px solid #3b3b3b;
  border-right: 5px solid #3b3b3b;
  transform: rotate(45deg);
  margin: -10px;
  animation: scroll 2s infinite;
}
.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}
.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes scroll {
  0% {
    opacity: 1;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px 20px);
  }
}

@keyframes myAnim6 {
  0% {
    height: 350px;
    width: 350px;
    background-color: #0bffc2;
  }
  30% {
    height: 450px;
    width: 450px;
    background-color: #b2f9ed;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes myAnim5 {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  30% {
    opacity: 1;
    width: 350px;
    height: 350px;
    background-color: #0bffc2;
  }
  60% {
    opacity: 1;
    height: 450px;
    width: 450px;
    background-color: #b2f9ed;
  }

  100% {
    background-color: #b2f9ed;

    opacity: 1;
    width: 350px;
    height: 350px;
  }
}

@media screen and (max-width: 1124px) {
  @keyframes myAnim6 {
    0% {
      height: 200px;
      width: 200px;
      background-color: #0bffc2;
    }
    30% {
      height: 350px;
      width: 350px;
      background-color: #b2f9ed;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes myAnim5 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    30% {
      opacity: 1;
      width: 200px;
      height: 200px;
      background-color: #0bffc2;
    }
    60% {
      opacity: 1;
      height: 350px;
      width: 350px;
      background-color: #b2f9ed;
    }

    100% {
      background-color: #b2f9ed;

      opacity: 1;
      width: 200px;
      height: 200px;
    }
  }
}

@media screen and (max-width: 612px) {
  @keyframes myAnim6 {
    0% {
      height: 200px;
      width: 200px;
      background-color: #0bffc2;
    }
    30% {
      height: 280px;
      width: 280px;
      background-color: #b2f9ed;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes myAnim5 {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    30% {
      opacity: 1;
      width: 200px;
      height: 200px;
      background-color: #0bffc2;
    }
    60% {
      opacity: 1;
      height: 280px;
      width: 280px;
      background-color: #b2f9ed;
    }

    100% {
      background-color: #b2f9ed;

      opacity: 1;
      width: 200px;
      height: 200px;
    }
  }
}

.sec1 {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: myAnim6 4s ease-in-out infinite;
}
.third1 {
  position: absolute;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: myAnim5 4s ease-in-out infinite;
}
.sec1lora {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: myAnim6 4s ease-in-out infinite;
}
.third1lora {
  position: absolute;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: myAnim5 4s ease-in-out infinite;
}
.button-grad-text{
  background: -webkit-linear-gradient(#03A0FF, #04FDF9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-shadow{
  box-shadow: rgba(255, 255, 255, 0.587) 0px -1px 0px;
}
.button-grad-text:hover{
 color: white !important;
 -webkit-background-clip: unset;
  -webkit-text-fill-color: white;
  background:linear-gradient(#03A0FF, #04FDF9);
}
@media screen and (max-width:764px) {
  .button-grad-text{
    color: white !important;
    -webkit-background-clip: unset;
     -webkit-text-fill-color: white;
     background:linear-gradient(#03A0FF, #04FDF9);
   }
}
.link-text-grad:hover{
  background: -webkit-linear-gradient(#03A0FF, #04FDF9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.prdtspec-text-grad:hover p{
  background: -webkit-linear-gradient(#03A0FF, #04FDF9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.trianglebg{
  background-image: url('./assets/images/trianglebg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left top;
}
@media screen and (max-width:768px) {
  .trianglebg{
    background-image: url('./assets/images/trianglebg.png');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: left top;
  }
}



@keyframes myAnim6lora {
  0% {
    height: 350px;
    width: 350px;
    background-color: #363736;
  }
  30% {
    height: 450px;
    width: 450px;
    background-color: #232323;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes myAnim5lora {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  30% {
    opacity: 1;
    width: 350px;
    height: 350px;
    background-color: #232323;
  }
  60% {
    opacity: 1;
    height: 450px;
    width: 450px;
    background-color: #3e3e3e;
  }

  100% {
    background-color: #3d3d3d;

    opacity: 1;
    width: 350px;
    height: 350px;
  }
}

@media screen and (max-width: 1028px) {
  @keyframes myAnim6lora {
    0% {
      height: 200px;
      width: 200px;
      background-color: #363736;
    }
    30% {
      height: 350px;
      width: 350px;
      background-color: #232323;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes myAnim5lora {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    30% {
      opacity: 1;
      width: 200px;
      height: 200px;
      background-color: #232323;
    }
    60% {
      opacity: 1;
      height: 350px;
      width: 350px;
      background-color: #3e3e3e;
    }

    100% {
      background-color: #3d3d3d;

      opacity: 1;
      width: 200px;
      height: 200px;
    }
  }
}

@media screen and (max-width: 612px) {
  @keyframes myAnim6lora {
    0% {
      height: 200px;
      width: 200px;
      background-color: #363736;
    }
    30% {
      height: 280px;
      width: 280px;
      background-color: #232323;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes myAnim5lora {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    30% {
      opacity: 1;
      width: 200px;
      height: 200px;
      background-color: #232323;
    }
    60% {
      opacity: 1;
      height: 280px;
      width: 280px;
      background-color: #3e3e3e;
    }

    100% {
      background-color: #3d3d3d;

      opacity: 1;
      width: 200px;
      height: 200px;
    }
  }
}


.sec1lora {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: myAnim6lora 4s ease-in-out infinite;
}
.third1lora {
  position: absolute;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: myAnim5lora 4s ease-in-out infinite;
}